

import Sidebar from './component/sidebar';
import Maincontent from './component/maincontent';

function App() {
  return (
    <>
      <Sidebar/>
      <Maincontent/>
    </>  
  );
}

export default App;
